import React from "react";
import { DashboardLayout, Navbar, Categories, DisplayCategories, Products, Banners, Footer, MainBanners } from "../Components";
import { NavLink, Outlet } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from 'react';
import "./Pages.css"
import { BannersColumn } from "../Components/Banners";

const Home = () => {
    
    const [Login, Setlogin] = useState(false)
    const [siteData, setSiteData] = useState({});
    const [displayOrder, setDisplayOrder] = useState(["MainBanners", "Banners"])

    useEffect(() => {
        const site_data = localStorage.getItem("site_data");
        if (site_data) {
            const parsedData = JSON.parse(site_data);
            // setSiteData(parsedData);
            // let website = JSON.parse(parsedData.copy_right).website;
            // console.log(website,"Freshby", "website");
            // if (website === "Freshby") {
            //     setDisplayOrder(["Banners", "MainBanners"])
            // }
        }
    }, []);
   
    console.log(Login)
    return (
        <>
              <div className="navbar-section">
                <Navbar LoginAccount={Login} />
            </div>
           
            {displayOrder.map((item, index) => {
                return (
                    <div key={index}>
                        {item === "MainBanners" && <MainBanners />}
                        {item === "Banners" && <Banners />}
                    </div>
                )
            })}
            <div className="categories-section">
                <Categories />
            </div>
            <div className="display-categories-section">
                <DisplayCategories Setlogin={Setlogin} LoginAccount={Login} />
            </div>
            {/* <BannersColumn /> */}
            <Footer />
        </>
    )

}
export { Home }