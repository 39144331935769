import React, { useState, useEffect } from "react"
import "./Banners.css"
import axiosConfig from "../../Service/axiosConfig"
import { NavLink, useNavigate } from 'react-router-dom';
import Slider from "react-slick";


const Banners = () => {

    var settings = {
        // centerMode: true,
        // centerPadding: '60px',
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '30px'
                }
            },
            {
                breakpoint: 766,
                settings: {
                    centerMode: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerPadding: '30px'
                }
            }
        ]
    };


    const [Data, SetData] = useState([])

    const GetData = async () => {
        try {
            const responce = await axiosConfig.get("/catlog/banners/?banner_type=slider&page_size=7&is_suspended=false")
            console.log(responce)
            SetData(responce.data.results)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetData();
        console.log("banner data", Data)
    }, [])

    return (
        <>
            <div className="banners-container">
                <div className="">
                    <Slider {...settings}>
                        {Data.map((bannerItem) => {
                            return (
                                <div className="banner-item" key={bannerItem.id}>
                                    <NavLink to={bannerItem.slug === null ? "#" : `${bannerItem.slug}`}>
                                        <div className="banner-img">
                                            <img src={bannerItem.image ? bannerItem.image.image : bannerItem.image} />
                                        </div>
                                    </NavLink>
                                </div>
                            )
                        })}


                    </Slider>
                </div>
            </div>
        </>
    )
}
export { Banners };