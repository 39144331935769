import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { ViewCart } from './ViewCart';
import { MdOutlineHome, MdWorkOutline, MdOtherHouses, MdOutlineAddCircleOutline } from "react-icons/md"
import { HiOutlineArrowLeft } from "react-icons/hi"
import axiosConfig from "../../Service/axiosConfig"
import { useSelector, useDispatch } from "react-redux";
import { selectCartTotal, selectCartProducts, fetchProducts } from "../../redux/Slices/cartSlice";
const ViewCartModal = (props) => {
    let dispatch = useDispatch()
    const total = useSelector(selectCartTotal);
    const products = useSelector(selectCartProducts);
    const { cart } = useSelector((state) => state);
    console.log(cart.products)
    const [Error, setError] = useState("")
    const [error, seterror] = useState(false);
    const [pincode, setPincode] = useState("")
    const [DefaultPincode, setDefaultPincode] = useState("")
    const [miniPopUp, setMiniPopUp] = useState(false)
    const [productAmount, setProductAmount] = useState({
        mrp: 0,
        discount: 0,
        delviryCharges: 0,
        PackingCharges: 0,
        totalAmount: 0,
        proamount: 0,
        delivery_waived: null,
        packing_waived: null
    })
    const { count,
        setCount,
        show,
        setShow,
        handleClose,
        orderAddress,
        setOrderAddress,
        setOrders,
        Order,
        ProductData,
        setTotalAmount,
        ChangeAddress,
        GetTheAddress,
        PayMentNavigate,
        handleLoginShow,
        Totalamount,
        MovetoCart,
        isMobile,
        HandleAddress,
        storeNull,
        Address,
        GetAddress,
        travelTime
    } = props
    const ProductMRP = async (data) => {
        if (!data || !Array.isArray(data)) {
            throw new Error("Invalid data provided");
        }

        const totalPrice = data.reduce((acc, product) => {
            const price = parseFloat(product.price);
            const quantity = parseInt(product.quantity);
            return acc + price * quantity;
        }, 0);

        const offerPrice = data.reduce((acc, product) => {
            const oprice = parseFloat(product.offer_price > 0 ? product.offer_price : product.price);
            const quantity = parseInt(product.quantity);
            return acc + oprice * quantity;
        }, 0);

        console.log(data, totalPrice, offerPrice, orderAddress);

        let delivery_charges = 0;
        let packing_charges = 0;
        let spike_comment = null;
        let totalwt = { stores: JSON.parse(localStorage.getItem("storeId")), products: [] };
        let delivery_waived = null;
        let packing_waived = null;
        let spike_percentage = null;
        let free_delivery_charges;

        if (JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional") {
            totalwt.area = orderAddress?.landmark;
            totalwt.pincode = orderAddress?.pincode;
        }

        data.forEach(item => {
            totalwt.products.push({
                id: item.id,
                min_qty: item.quantity,
            });
            ProductData.push({
                "id": item.id,
                "min_qty": item.quantity
            });
        });

        try {
            axiosConfig.defaults.headers['Content-Type'] = 'application/json';
            const charges_response = await axiosConfig.post('accounts/get_delivery_packing_charges/', totalwt);

            if (!charges_response || !charges_response.data) {
                throw new Error("Invalid response from server");
            }

            const responseData = charges_response.data;

            if (JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional") {
                if (responseData.message) {
                    setError(responseData.message);
                } else {
                    delivery_charges = responseData.conventional_delivery_waiver_charges ? 0 : responseData.conventional_delivery_charges;
                    packing_charges = responseData.conventional_packing_waiver_charges ? 0 : responseData.conventional_packing_charges;
                    delivery_waived = responseData.conventional_delivery_waiver_charges ? responseData.conventional_delivery_charges : null;
                    packing_waived = responseData.conventional_packing_waiver_charges ? responseData.conventional_packing_charges : null;
                    spike_comment = responseData.spike_comment || null;
                    spike_percentage = responseData.spike_percentage || null;
                    setError("");
                    if (orderAddress) {
                        localStorage.setItem("address_id", orderAddress.id);
                        localStorage.setItem("address", `${orderAddress.address}, ${orderAddress.landmark}, ${orderAddress.pincode}`);
                    }
                }
            } else {
                delivery_charges = responseData.express_waier_delivery_charges ? 0 : responseData.express_delivery_charges;
                packing_charges = responseData.express_waier_packing_charges ? 0 : responseData.express_packing_charges;
                spike_comment = responseData.spike_comment || null;
                delivery_waived = responseData.express_waier_delivery_charges ? responseData.express_delivery_charges : null;
                packing_waived = responseData.express_waier_packing_charges ? responseData.express_packing_charges : null;
                spike_percentage = responseData.spike_percentage || null;
                free_delivery_charges = responseData.free_delivery_charges || false;
                setError("");
            }

            setTotalAmount({
                Total_amount: cart.total + delivery_charges + packing_charges
            });

            setOrders({
                ...Order,
                packing_charges,
                delivery_charges,
                spike_comment,
                spike_percentage,
                stores: localStorage.getItem("storeId"),
                user_id: parseFloat(localStorage.getItem("userId")),
                address: JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional" && orderAddress ? orderAddress.id : localStorage.getItem("address_id"),
                delivery_type: JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional" ? "" : "Express",
                products: ProductData,
                free_delivery_charges
            });

            setProductAmount && setProductAmount({
                mrp: totalPrice,
                discount: totalPrice - offerPrice,
                deliveryCharges: delivery_charges,
                PackingCharges: packing_charges,
                totalAmount: cart.total + delivery_charges + packing_charges,
                proamount: totalPrice,
                delivery_waived,
                packing_waived
            });

            console.error('Geolocation is not supported by this browser.');
        } catch (error) {
            console.log(error.response?.data?.error || "Delivery charges not found for the given parameters");
        }
    };

    const localityFilter = async () => {
        if (pincode.length === 6) {
            try {
                const res = await axiosConfig.get(`/masters/areas/?city=&is_suspended=false&pincode=${pincode}`);
                if (res.data.results.length > 0) {
                    localStorage.setItem("pincode", pincode);
                    setDefaultPincode(pincode)
                    setPincode("")
                    setError(false);
                } else {
                    setError(true);
                    setDefaultPincode("")
                    setPincode("")
                }
            } catch (error) {
                console.error(error);
                setError(true);
                setDefaultPincode("")
                setPincode("")
            }
        } else {
            console.log("disbled")
            setDefaultPincode("")
            setPincode("")
        }
    };
    useEffect(() => {
        ProductMRP(cart.products)
        const pincode = localStorage.getItem("pincode")
        if (pincode) {
            setDefaultPincode(pincode)
        }
    }, [cart.products, localStorage.getItem("latitude"), orderAddress, localStorage.getItem("longitude")])
    return (
        <div>
            {count === 1 && <Modal show={show} onHide={handleClose} className={`popup-cart ${ miniPopUp? 'zindex-0':''}`}>
                <Modal.Header closeButton>
                    <Modal.Title>My Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body className={orderAddress ? "cart-modal-rn" : "cart-modal-address"}>
                    <div>
                        <ViewCart handleClose={handleClose} ProductData={ProductData} Order={Order} orderAddress={orderAddress} setOrders={setOrders} setProductAmount={setProductAmount} productAmount={productAmount} setTotalAmount={setTotalAmount} travelTime={travelTime} setMiniPopUp={setMiniPopUp} />
                    </div>
                </Modal.Body>
                {products.length > 0 ? <Modal.Footer>
                    {/* <div className="delivery-location">
                        {orderAddress && <div className="row">
                            <div className="col-2 nav-locat-icon">
                                {orderAddress.adress_type === "Home" && <MdOutlineHome /> || orderAddress.adress_type === "Work" && <MdWorkOutline /> || orderAddress.adress_type === "Others" && <MdOtherHouses />}

                            </div>
                            <div className="col-10">
                                <p className="add-type">Delivering to {orderAddress.adress_type}</p>
                                <div className="row">
                                    <div className="col-10">
                                        <p className="del-address">{orderAddress.name},{orderAddress.flat_no},{orderAddress.address},{orderAddress.landmark},{orderAddress.pincode}</p>
                                    </div>
                                    <div className="col-2 change-nav" onClick={ChangeAddress}>
                                        <p>Change</p>
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div> */}
                    <button className="btn btn-success btn-proceed" onClick={localStorage.getItem("userId") ? orderAddress === undefined ? GetTheAddress : PayMentNavigate : handleLoginShow}>
                        <p className="p-qty">{cart.products.length > 0 ? (
                            <>
                                {/* <div>
                                    <span className="p-price">₹{parseFloat(Totalamount.Total_amount).toFixed(2)}</span>
                                    <div className="total-text">TOTAL</div>
                                </div> */}
                                <p className="p-pay">Proceed To Pay</p>
                            </>
                        ) : (
                            <p>My Cart</p>
                        )}
                        </p>
                    </button>

                </Modal.Footer> : ""}
            </Modal>}
            {count === 2 && <Modal show={show} onHide={handleClose} className="popup-cart">
                <Modal.Header>
                    <Modal.Title>
                        <div className="cart-address">
                            <div className="left-arrrow" onClick={MovetoCart}>
                                <HiOutlineArrowLeft />
                            </div>
                            <div className="left-address">
                                {JSON.parse(localStorage.getItem("site_data")).ecommerce_type !== "Conventional" ? "My Address" : "Enter Delivery Details"}
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={isMobile ? "cart-modal-rn-address-mobile" : "cart-modal-rn-address"}>
                    <div className="add-address-main">
                        {JSON.parse(localStorage.getItem("site_data")).ecommerce_type !== "Conventional" ?
                            <div className="add-address-nav" onClick={HandleAddress}>
                                <div className="add-icon-nav">
                                    <MdOutlineAddCircleOutline />
                                </div>
                                <div className="address-head-nav">
                                    Add a new address in
                                </div>
                            </div> :
                            DefaultPincode ? <div className='addressStripV2-base-desktopContainer '><div className='addressStripV2-base-title'>Deliver to:{localStorage.getItem("pincode")}</div><button className='btn btn-primary' onClick={() => setDefaultPincode("")}>Change Address</button></div> : <div className='inputV2-base-inputRow '>
                                <input class=" inputV2-base-input " id="pincode" placeholder="Enter Pincode" type="tel" maxlength="6" onChange={(e) => setPincode(e.target.value)} />
                                <div class={`CheckDeliveryModalV2-base-checkBtn ${pincode.length === 6 ? "" : "CheckDeliveryModalV2-base-inValid"}`} onClick={localityFilter}>CHECK</div>
                                {error &&
                                    <div className="Loginerror">Service not available for this selected pincode</div>
                                }
                            </div>}
                        <div>
                            {Address.length > 0 && <div className="address-p">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div>Choose Delivery Address</div>
                                    {JSON.parse(localStorage.getItem("site_data")).ecommerce_type === "Conventional" && <div className="address-head-nav" onClick={HandleAddress} style={{ color: "#3cac47", cursor: "pointer" }}>
                                        Add a new address in
                                    </div>}
                                </div>
                            </div>}
                            {storeNull &&
                                <div className="Loginerror">Currently, service not available in the selected address</div>
                            }
                            {Error &&
                                <div className="Loginerror">Service not available for this selected pincode</div>
                            }
                        </div>
                        <div className="address-container">
                            {Address.length > 0 ? Address.map((address, i) => (
                                <div key={i} className="address-main d-flex align-items-center">
                                    <div className="form-check" onClick={() => {
                                        const siteData = JSON.parse(localStorage.getItem("site_data"));
                                        if (siteData.ecommerce_type !== "Conventional") {
                                            GetAddress(address.id);
                                        } else {
                                            localStorage.setItem("pincode", address?.pincode);
                                            setOrderAddress(address);
                                        }
                                    }}>
                                        <input className="form-check-input" type="radio" name="exampleRadios" id={`exampleRadios${i}`} value={`option${i}`} />
                                    </div>
                                    <div>
                                        <div className="address-ass">
                                            {address.address_type}
                                        </div>
                                        <div className="address-detail">
                                            {`${address.address ? `${address.address},` : ''} ${address.landmark ? `${address.landmark},` : ''} ${address.pincode ? `${address.pincode}` : ''}`}
                                        </div>
                                    </div>
                                </div>
                            )) : <div><button className='btn btn-primary btn-block' onClick={HandleAddress}>Add New Address</button></div>}
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success btn-proceed" disabled={!orderAddress || Error} onClick={() => setCount(1)}>
                        <p className="p-pay">Done</p>
                    </button>
                </Modal.Footer>
            </Modal>}
        </div>
    );
};

export default ViewCartModal;
