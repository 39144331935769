import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Login } from "../Login";
import { NavLink } from "react-router-dom";
const LoginModal = ({ LoginType, setLoginType, setLoggedIn, LoggedIn }) => {
  const [loginShow, setloginShow] = useState(false);
  const [Step, SetStep] = useState("1");
  const [number, setNumber] = useState({});
  const handleLoginShow = () => setloginShow(true);
  const handleLoginClose = () => {
    setLoginType(false);
    SetStep(1);
    setNumber({});
  };
  const HandleModal = () => {
    SetStep(1);
    setNumber({});
  };
  function handleLoginEvent() {
    handleLoginClose(false);
    setLoggedIn && setLoggedIn(!LoggedIn);
  }
  const getLogin = () => {
    if (LoginType) {
      handleLoginShow();
    } else {
      handleLoginClose(false);
    }
  };
  useEffect(() => {
    getLogin();
  }, [LoginType]);
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={loginShow}
      onHide={handleLoginClose}
      className="login-popup"
    >
      <Modal.Header closeButton>
        {/* {number.mobile ? (
          <Modal.Title className="modal-tile-back" onClick={HandleModal}>
            Back
          </Modal.Title>
        ) : ( */}
        <Modal.Title className="login-head__text weight--light">
          {/* Phone Number Verification */}
        </Modal.Title>
        {/* )} */}
      </Modal.Header>
      <Modal.Body>
        <div className="logoin_page_header">
          <div className="login_apge_left">
            <div className="login-head">
              {/* <h2 className="login-head__text weight--light">
            Phone Number Verification
          </h2> */}
            </div>
            <Login
              handleLoginEvent={handleLoginEvent}
              setNumber={setNumber}
              number={number}
              SetStep={SetStep}
              Step={Step}
              HandleModal={HandleModal}
            />
            <div className="loginfooter">
              {number.mobile ? null : <p>By continuing, you agree to our</p>}
              {number.mobile ? null : (
                <NavLink to="/terms-services">Terms of service & </NavLink>
              )}
              {number.mobile ? null : (
                <NavLink to="/privacy">Privacy policy</NavLink>
              )}
            </div>
          </div>
          <div className="login_page_right">
            <div className="login_page_content">
              <h1>LOGIN</h1>
              <p>Get access to your Orders, Wishlist and Recommendations</p>
              <img src={require('./images/loginsearch.png')} alt="icon" />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export { LoginModal };
